// @flow
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Back from '../components/Back';

const Kontakt = (props) => (
  <Layout className={props.transitionStatus}>
    <SEO title="Kontakt" theme="dark" />
		<article>
			<h1>Kontakt</h1>
			<section className="separator separator--bottom helper--tight">
				<p>
					<strong>AlbrechtMink GbR</strong><br/>
					Nikolaus Albrecht &amp; Werner Mink<br/>
					Seitzstr. 6a<br />
					80538 München
				</p>
			</section>
			<section className="separator separator--bottom helper--tight">
				<p>
					<strong><a href="mailto:albrecht@albrechtmink.com" className="type--email">albrecht@albrechtmink.com</a></strong><br />
					<strong><a href="mailto:mink@albrechtmink.com" className="type--email">mink@albrechtmink.com</a></strong> <br />
					<strong><a href="mailto:info@albrechtmink.com" className="type--email">info@albrechtmink.com</a></strong> 
				</p>
			</section>
			<p>
				Telefon: <a href="tel+49 (0)170 636 52 62">+49 (0)170 636 52 62</a>
			</p>
			<Back />
		</article>
  </Layout>
);

export default Kontakt;
